<template>
  <div class="actions">
    <router-link
      :to="{name: 'update_anamnese', params: {uid: object.uid}}"
      href="javascript:void(0)"
      class="btn btn-sm  btn-primary"
      v-if="canUpdate"
    >
      <i class="icofont icofont-pencil" />
    </router-link>
    <router-link
      :to="{name: 'detail_anamnese', params: {uid: object.uid}}"
      class="btn btn-sm  btn-secondary"
    >
      <i class="icofont icofont-eye" />
    </router-link>
    <a
      href="javascript:void(0)"
      class="btn btn-sm btn-third"
      @click.prevent="deleting"
      v-if="canDelete"
    >
      <i class="icofont icofont-close" />
    </a>
  </div>
</template>

<script>
import { DELETE_ANAMNESE } from '../../../graphql/sanitaire'; 
import { mapGetters } from 'vuex';
export default {
    props: {object: {type: Object, required: true}},
    data(){
        return  {

        }
    },
    methods: {
        deleting(){
            this.$apollo.mutate({
                mutation: DELETE_ANAMNESE,
                variables: {
                    "uid": this.object.uid
                },
                update: (data) => {
                    if(data) console.log(`Anamnese ${this.object.uid} deleted successfully`)
                    else console.log(`Error in deletting analyse ${this.object.uid}`)
                }
              })
        }
    },
    computed: {
      ...mapGetters({
        is_super_admin: 'auth/is_super_admin',
        is_veterinaire: 'auth/is_veterinaire',
        is_assistant_veto: 'auth/is_assistant_veterinaire',
        is_assistant_veto_major: 'auth/is_assistant_veto_major'
      }),
      canUpdate(){
        return this.is_super_admin || this.is_veterinaire || this.is_assistant_veto_major
      },
      canDelete(){
        return this.is_super_admin || this.is_veterinaire || this.is_assistant_veto_major
      }
    }


}
</script>

<style lang="scss" scoped>
.actions{
    a{
        margin-left: 5px;
    }
}
</style>